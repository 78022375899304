import clsx from 'clsx'
import {
  $getSelection,
  $isRangeSelection,
  $isRootOrShadowRoot,
  FORMAT_TEXT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  UNDO_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  KEY_MODIFIER_COMMAND,
  COMMAND_PRIORITY_NORMAL,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
} from 'lexical'
import { TOGGLE_LINK_COMMAND, $isLinkNode } from '@lexical/link'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $isListNode, ListNode } from '@lexical/list'
import {
  mergeRegister,
  $findMatchingParent,
  $getNearestNodeOfType,
} from '@lexical/utils'

import { MouseEvent, useCallback, useEffect, useState } from 'react'
import { sanitizeUrl, getSelectedNode } from '~common/utils/lexical'
import BlockFormatMenu, { blockTypeToBlockName } from './BlockFormatMenu'
import { $isHeadingNode, $isQuoteNode } from '@lexical/rich-text'
import {
  FaAlignCenter,
  FaAlignJustify,
  FaAlignLeft,
  FaAlignRight,
  FaRotateLeft,
  FaRotateRight,
  FaBold,
  FaStrikethrough,
  FaItalic,
  FaUnderline,
  FaLink,
} from 'react-icons/fa6'

export const ToolbarPlugin = () => {
  const [editor] = useLexicalComposerContext()
  const [activeEditor, setActiveEditor] = useState(editor)

  const [blockType, setBlockType] =
    useState<keyof typeof blockTypeToBlockName>('paragraph')

  const [isBold, setIsBold] = useState<boolean>(false)
  const [isItalic, setIsItalic] = useState<boolean>(false)
  const [isStrikethrough, setIsStrikethrough] = useState<boolean>(false)
  const [isUnderline, setIsUnderline] = useState<boolean>(false)
  const [isLink, setIsLink] = useState<boolean>(false)
  const [canUndo, setCanUndo] = useState<boolean>(false)
  const [canRedo, setCanRedo] = useState<boolean>(false)

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection()

    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode()
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e: any) => {
              const parent = e.getParent()
              return parent !== null && $isRootOrShadowRoot(parent)
            })

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow()
      }

      const elementKey = element.getKey()
      const elementDOM = activeEditor.getElementByKey(elementKey)

      setIsBold(selection.hasFormat('bold'))
      setIsItalic(selection.hasFormat('italic'))
      setIsStrikethrough(selection.hasFormat('strikethrough'))
      setIsUnderline(selection.hasFormat('underline'))

      // Update links
      const node = getSelectedNode(selection)
      const parent = node.getParent()
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true)
      } else {
        setIsLink(false)
      }

      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(
            anchorNode,
            ListNode,
          )
          const type = parentList
            ? parentList.getListType()
            : element.getListType()
          setBlockType(type)
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType()
          if (type in blockTypeToBlockName) {
            setBlockType(type as keyof typeof blockTypeToBlockName)
          }
        }
      }
    }
  }, [activeEditor])

  useEffect(() => {
    return mergeRegister(
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar()
        })
      }),
    )
  }, [$updateToolbar, editor])

  useEffect(() => {
    return mergeRegister(
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar()
        })
      }),
      activeEditor.registerCommand<boolean>(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload)
          return false
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
      activeEditor.registerCommand<boolean>(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload)
          return false
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
    )
  }, [$updateToolbar, activeEditor, editor])

  useEffect(() => {
    return activeEditor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        $updateToolbar()
        setActiveEditor(newEditor)
        return false
      },
      COMMAND_PRIORITY_CRITICAL,
    )
  }, [editor, $updateToolbar])

  useEffect(() => {
    return activeEditor.registerCommand(
      KEY_MODIFIER_COMMAND,
      (payload) => {
        const event: KeyboardEvent = payload
        const { code, ctrlKey, metaKey } = event

        if (code === 'KeyK' && (ctrlKey || metaKey)) {
          event.preventDefault()
          return activeEditor.dispatchCommand(
            TOGGLE_LINK_COMMAND,
            sanitizeUrl('https://'),
          )
        }
        return false
      },
      COMMAND_PRIORITY_NORMAL,
    )
  }, [activeEditor, isLink])

  const insertLink = useCallback(() => {
    if (!isLink) {
      activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl('https://'))
    } else {
      activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
    }
  }, [activeEditor, isLink])

  return (
    <div className="z-[5] flex gap-0.5 p-1 h-10 p-2 py-2 mt-1 space-x-2 flex items-center rounded-lg border bg-white border-blue-gray-50 dark:bg-slate-700 dark:border-slate-500">
      <button
        className={clsx(
          'p-2 bg-transparent hover:bg-primary-100 dark:bg-slate-700 dark:hover:bg-slate-500 rounded-md transition-all duration-100 ease-in',
        )}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.preventDefault()
          activeEditor.dispatchCommand(UNDO_COMMAND, undefined)
        }}
      >
        <FaRotateLeft
          className={clsx(
            'w-3.5 h-3.5',
            canUndo
              ? 'text-gray-700 dark:text-gray-300'
              : 'text-gray-400 dark:text-gray-400',
          )}
        />
      </button>
      <button
        className={clsx(
          'p-2 bg-transparent hover:bg-primary-100 dark:bg-slate-700 dark:hover:bg-slate-500 rounded-md transition-all duration-100 ease-in',
        )}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.preventDefault()
          activeEditor.dispatchCommand(REDO_COMMAND, undefined)
        }}
      >
        <FaRotateRight
          className={clsx(
            'w-3.5 h-3.5',
            canRedo
              ? 'text-gray-700 dark:text-gray-300'
              : 'text-gray-400 dark:text-gray-400',
          )}
        />
      </button>

      <span className="w-[1px] bg-gray-600 block h-full" />
      <button
        className={clsx(
          'p-2 hover:bg-primary-100 dark:bg-slate-700 dark:hover:bg-slate-500 rounded-md rounded-md transition-all duration-100 ease-in',
          isBold
            ? 'bg-primary-100 dark:bg-slate-900'
            : 'bg-transparent  dark:bg-slate-700',
        )}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.preventDefault()
          activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')
        }}
      >
        <FaBold className="w-3.5 h-3.5 text-gray-700 dark:text-slate-400" />
      </button>
      <button
        className={clsx(
          'p-2 hover:bg-primary-100 dark:hover:bg-slate-500 rounded-md transition-all duration-100 ease-in',
          isStrikethrough ? 'bg-primary-100' : 'bg-transparent',
        )}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.preventDefault()
          activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough')
        }}
      >
        <FaStrikethrough className="w-3.5 h-3.5 text-gray-700 dark:text-slate-400" />
      </button>
      <button
        className={clsx(
          'p-2 hover:bg-primary-100 dark:hover:bg-slate-500 rounded-md transition-all duration-100 ease-in',
          isItalic ? 'bg-primary-100' : 'bg-transparent',
        )}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.preventDefault()
          activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')
        }}
      >
        <FaItalic className="w-3.5 h-3.5 text-gray-700 dark:text-slate-400" />
      </button>
      <button
        className={clsx(
          'p-2 hover:bg-primary-100 dark:hover:bg-slate-500 rounded-md transition-all duration-100 ease-in',
          isUnderline ? 'bg-primary-100' : 'bg-transparent',
        )}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.preventDefault()
          activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
        }}
      >
        <FaUnderline className="text-gray-700 dark:text-slate-400 w-3.5 h-3.5" />
      </button>
      <button
        className={clsx(
          'p-2 hover:bg-primary-100 dark:hover:bg-slate-500 rounded-md transition-all duration-100 ease-in',
          isLink ? 'bg-primary-100' : 'bg-transparent',
        )}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.preventDefault()
          insertLink()
        }}
      >
        <FaLink className="w-3.5 h-3.5 text-gray-700 dark:text-slate-400" />
      </button>

      <span className="w-[1px] bg-gray-600 block h-full"></span>

      <button
        className={clsx(
          'p-2 bg-transparent hover:bg-primary-100 dark:bg-slate-700 dark:hover:bg-slate-500 rounded-md transition-all duration-100 ease-in',
        )}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.preventDefault()
          activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left')
        }}
      >
        <FaAlignLeft className="w-3.5 h-3.5 text-gray-700 dark:text-slate-400" />
      </button>
      <button
        className={clsx(
          'p-2 bg-transparent hover:bg-primary-100 dark:bg-slate-700 dark:hover:bg-slate-500 rounded-md transition-all duration-100 ease-in',
        )}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.preventDefault()
          activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center')
        }}
      >
        <FaAlignCenter className="w-3.5 h-3.5 text-gray-700 dark:text-slate-400" />
      </button>
      <button
        className={clsx(
          'p-2 bg-transparent hover:bg-primary-100 dark:bg-slate-700 dark:hover:bg-slate-500 rounded-md transition-all duration-100 ease-in',
        )}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.preventDefault()
          activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right')
        }}
      >
        <FaAlignRight className="w-3.5 h-3.5 text-gray-700 dark:text-slate-400" />
      </button>
      <button
        className={clsx(
          'p-2 bg-transparent hover:bg-primary-100 dark:bg-slate-700 dark:hover:bg-slate-500 rounded-md transition-all duration-100 ease-in',
        )}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.preventDefault()
          activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify')
        }}
      >
        <FaAlignJustify className="w-3.5 h-3.5 text-gray-700 dark:text-slate-400" />
      </button>

      <span className="w-[1px] bg-gray-600 block h-full" />

      {blockType in blockTypeToBlockName && activeEditor === editor && (
        <BlockFormatMenu editor={activeEditor} blockType={blockType} />
      )}
    </div>
  )
}

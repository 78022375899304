import { SUPPORTED_URL_PROTOCOLS } from '~common/constants'

import { $isAtNodeEnd } from '@lexical/selection'
import { RangeSelection } from 'lexical/LexicalSelection'
import { ElementNode } from 'lexical/nodes/LexicalElementNode'
import { TextNode } from 'lexical/nodes/LexicalTextNode'

export function sanitizeUrl(url: string): string {
  try {
    const parsedUrl = new URL(url)
    // eslint-disable-next-line no-script-url
    if (!SUPPORTED_URL_PROTOCOLS.has(parsedUrl.protocol)) {
      return 'about:blank'
    }
  } catch {
    return url
  }
  return url
}

// Lexical
export function getSelectedNode(
  selection: RangeSelection,
): TextNode | ElementNode {
  const anchor = selection.anchor
  const focus = selection.focus
  const anchorNode = selection.anchor.getNode()
  const focusNode = selection.focus.getNode()
  if (anchorNode === focusNode) {
    return anchorNode
  }
  const isBackward = selection.isBackward()
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode
  } else {
    return $isAtNodeEnd(anchor) ? anchorNode : focusNode
  }
}

export const getValidLexicalEditorContent = (value: string) => {
  if (!value.length || !value.includes('root')) {
    return `{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"${'value'}","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}`
  }
  return value
}

export const CAN_USE_DOM: boolean =
  typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'

import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { HorizontalRulePlugin } from '@lexical/react/LexicalHorizontalRulePlugin'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin'
import { EditorState } from 'lexical'
import { useCallback, useEffect, useRef, useState } from 'react'
import { ToolbarPlugin } from './plugins/ToolbarPlugin'
import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
  TRANSFORMERS,
} from '@lexical/markdown'
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin'
import { TreeViewPlugin } from './plugins/TreeViewPlugin'
import { EditorConfig } from './EditorConfig'
import { EditorPlaceholder } from './EditorPlaceholder'
import {
  CAN_USE_DOM,
  getValidLexicalEditorContent,
} from '~common/utils/lexical'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin'
import ReadOnlyPlugin from './plugins/ReadOnlyPlugin'
import clsx from 'clsx'

export type LexicalEditorProps = {
  editorContent: any
  onChange: (value: any) => void
  isReadOnly?: boolean
}

const LexicalEditor = ({
  editorContent,
  onChange,
  isReadOnly = false,
}: LexicalEditorProps) => {
  const [cardContent, setCardContent] = useState<any>(editorContent)

  const _floatingAnchorElem = useRef<HTMLDivElement>(null)

  const [isSmallWidthViewport, setIsSmallWidthViewport] =
    useState<boolean>(false)

  useEffect(() => {
    setCardContent(editorContent)
  }, [editorContent])

  useEffect(() => {
    const updateViewPortWidth = () => {
      const isNextSmallWidthViewport =
        CAN_USE_DOM && window.matchMedia('(max-width: 1025px)').matches

      if (isNextSmallWidthViewport !== isSmallWidthViewport) {
        setIsSmallWidthViewport(isNextSmallWidthViewport)
      }
    }
    updateViewPortWidth()
    window.addEventListener('resize', updateViewPortWidth)

    return () => {
      window.removeEventListener('resize', updateViewPortWidth)
    }
  }, [isSmallWidthViewport])

  const onChangeEditor = (editorState: EditorState) => {
    editorState.read(() => {
      const markdown = $convertToMarkdownString(TRANSFORMERS)
      setCardContent(markdown)
      onChange(markdown)
    })
  }

  return (
    <LexicalComposer
      initialConfig={{
        ...EditorConfig,
        editorState: () => $convertFromMarkdownString(cardContent),
        editable: !isReadOnly,
      }}
    >
      <div className="w-full h-content resize-none overflow-hidden">
        <RichTextPlugin
          contentEditable={
            <div className="relative" ref={_floatingAnchorElem}>
              <ContentEditable
                className={clsx(
                  "relative z-0 min-h-[240px] resize-none text-base outline-0 px-4 py-2.5 rounded-md border", 
                  "border-gray-100 caret-gray-800",
                  "dark:bg-slate-900 dark:text-slate-400 dark:border-slate-600 dark:caret-slate-50"
                )}
                disabled={isReadOnly}
              />
            </div>
          }
          placeholder={<></>}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <ReadOnlyPlugin isReadOnly={isReadOnly} />
        <HistoryPlugin />
        <LinkPlugin />
        <ListPlugin />
        <AutoFocusPlugin />
        {/* <TreeViewPlugin /> */}
        <TabIndentationPlugin />
        <HorizontalRulePlugin />
        <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
        <OnChangePlugin ignoreSelectionChange onChange={onChangeEditor} />
        {!isSmallWidthViewport &&
          _floatingAnchorElem &&
          _floatingAnchorElem.current &&
          !isReadOnly && (
            <FloatingLinkEditorPlugin
              anchorElem={_floatingAnchorElem.current}
            />
          )}
      </div>
      {!isReadOnly && <ToolbarPlugin />}
    </LexicalComposer>
  )
}

export default LexicalEditor

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { useEffect } from 'react'

type ReadOnlyPluginProps = {
  isReadOnly: boolean
}

export default function ReadOnlyPlugin({
  isReadOnly,
}: ReadOnlyPluginProps): JSX.Element | null {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    editor.setEditable(!isReadOnly)
  }, [editor, isReadOnly])

  return null
}
